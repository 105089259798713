<template>
  <footer class="footer footer-bg">
    <div class="container-fluid" style="margin: -30px; margin-bottom: -50px;">
      <div class="row d-flex justify-content-between">
        <div class="col-lg-3" style="text-align:center">
          <a href="https://ayun.ph">
            <img src="img/brand/optimized/Ayun-white.png" class="img-fluid mb-5" width="200" alt="Ayun!" />
          </a>
        </div>
        <div class="col-lg-2 text-white">
          <h5 class="text-white font-weight-medium">
            Quick Links
          </h5>
          <ul class="list-unstyled align-items-left">
            <li>
              <router-link slot="title" v-scroll-to="'/'" to="/" class="nav-link text-white font-weight-light pt-0 pb-0"
                data-toggle="dropdown" role="button" style="font-size: 15px;">
                Home
              </router-link>
            </li>
            <li>
              <router-link slot="title" v-scroll-to="'/#about-us'" to="/#about-us"
                class="nav-link text-white font-weight-light pt-0 pb-0" data-toggle="dropdown" role="button"
                style="font-size: 15px;">
                About Us
              </router-link>
            </li>
            <li>
              <router-link slot="title" v-scroll-to="'/#app-comparison'" to="/#app-comparison"
                class="nav-link text-white font-weight-light pt-0 pb-0" data-toggle="dropdown" role="button"
                style="font-size: 15px;">
                Ayun! Comparison
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 text-white">
          <h5 class="text-white font-weight-medium">AB Apps</h5>
          <ul class="list-unstyled align-items-left">
            <li>
              <router-link to="/ayun-asset-based#ab-inventory" class="nav-link text-white font-weight-light pt-0 pb-0"
                data-toggle="tooltip" title="Ayun! AB Inventory" style="font-size: 15px;">
                Ayun! AB Inventory
              </router-link>
            </li>
            <li>
              <router-link to="/ayun-asset-based#ab-dispatch" class="nav-link text-white font-weight-light pt-0 pb-0"
                data-toggle="tooltip" title="Ayun! AB Dispatch" style="font-size: 15px;">
                Ayun! AB Dispatch
              </router-link>
            </li>
            <li>
              <router-link to="/ayun-asset-based#ab-sticker-printer"
                class="nav-link text-white font-weight-light pt-0 pb-0" data-toggle="tooltip"
                title="Ayun! AB Sticker Printer" style="font-size: 15px;">
                Ayun! AB Sticker Printer
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 text-white">
          <h5 class="text-white font-weight-medium">QB Apps</h5>
          <ul class="list-unstyled align-items-left">
            <li>
              <router-link to="/ayun-quantity-based#qb-dispatch" class="nav-link text-white font-weight-light pt-0 pb-0"
                data-toggle="tooltip" title="Ayun! QB Dispatch" style="font-size: 15px;">
                Ayun! QB Dispatch
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-lg-2 text-white">
          <h5 class="text-white font-weight-medium">Others</h5>
          <ul class="list-unstyled align-items-left">
            <li>
              <router-link to="/faqs" class="nav-link text-white font-weight-light pt-0 pb-0" data-toggle="tooltip"
                title="FAQs" style="font-size: 15px;">
                User FAQs
              </router-link>
            </li>
            <li>
              <router-link slot="title" v-scroll-to="'#contact-us'" to="/#contact-us"
                class="nav-link text-white font-weight-light pt-0 pb-0" data-toggle="dropdown" role="button"
                style="font-size: 15px;">
                Contact Us
              </router-link>
            </li>
            <li>
              <router-link to="/privacy" class="nav-link text-white font-weight-light pt-0 pb-0" data-toggle="tooltip"
                title="Privacy Policy" style="font-size: 15px;">
                Privacy Policy
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <hr />

    <div class="container-fluid">
      <div class="col-lg-2 col-md-2 col-sm-3 col-4 d-flex justify-content-around py-1" style="z-index: 100;">
        <a href="https://www.youtube.com/@tawitechincorporated2933" target="_blank"><i
            class="fa fa-youtube-play fa-lg text-white mr-2"></i></a>
        <a href="https://www.instagram.com/ayun_ph/" target="_blank"><i
            class="fa fa-instagram fa-lg text-white mr-2"></i></a>
        <a href="https://www.facebook.com/ayunph" target="_blank"><i class="fa fa-facebook fa-lg text-white "></i></a>
      </div>
      <div class=" justify-content-md-between justify-content-sm-around justify-content-around">
        <div class="col-md-12  py-1" style="text-align: right; margin-top: -40px; margin-bottom: -60px;">
          <div class="copyright text-secondary">
            Ayun! &copy; {{ year }}
            <a href="https://tawitech.ph" target="_blank">
              <img src="img/brand/optimized/tawitech_logo_white.png" width="auto" height="40" margin-left="30px"
                alt="TAWItech" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  name: "app-footer",
  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss">
.footer {
  background-color: #122b91;

  .container {
    max-width: 1280px;
  }

  .img-fluid {
    max-width: 150px;
    width: 100%;
  }

  h5 {
    font-size: 18px;
    font-weight: bold;
    position: relative;
    color: #f18f01 !important;
  }

  hr {
    border-top: 0.0625rem solid rgba(255, 255, 255, 0.5);
    margin-bottom: 10px;
  }

  .nav-link {
    margin: 10px 0;
  }
}

.footer-bg {
  background-color: #122b91 !important;
}

li {
  margin-top: -5%;
}

@media screen and (max-width: 767px) {
  .copyright {
    text-align: center;
  }

  h5 {
    margin-left: 50px;
  }

  li {
    margin-left: 50px;
  }
}

@media screen and (max-width: 767px) {
  .copyright {
    text-align: center;
  }

  h5 {
    margin-left: 50px;
  }

  li {
    margin-left: 50px;
  }
}

@media screen and (max-width: 990px) {
  .copyright {
    text-align: center;
  }

  h5 {
    margin-left: 50px;
    margin-bottom: 5px;
  }

  li {
    margin-left: 50px;
    margin-top: -10px;
  }
}
</style>
